/* Common */

:focus {
  outline: 0;
  border: none;
}

.border-right-none {
  border-right: none !important;
}
.border-left-none {
  border-left: none !important;
}
.border-top-none {
  border-top: none !important;
}
.border-bottom-none {
  border-left: none !important;
}

.table-no-border-left-right {
  border-left: none !important;
  border-right: none !important;
  border-collapse: collapse;
}

.max-content {
  width: max-content;
  width: "-webkit-max-content";
  width: "-moz-max-content";
}

.table-no-border-left-right tr td:last-of-type,
.table-no-border-left-right tr th:last-of-type {
  border-right: none !important;
}

.table-no-border-left-right tr td:first-of-type,
.table-no-border-left-right tr th:first-of-type {
  border-left: none !important;
}
/* EndCommon */

/* ReactDatePicker */

/* .react-datepicker__year-read-view--down-arrow {
  top: 3px;
} */

#formSalesReport tr:nth-of-type(even),
#SalesDailyReport tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

.wrap-budget {
  overflow-x: hidden;
  overflow-y: auto;
}
.wrap-budget .table-condensed {
  border-collapse: collapse;
  background-color: "#f9f9f9";
}
.wrap-budget .table-condensed td,
.wrap-budget .table-condensed th {
  border: 1px solid #222d32;
  font-size: 85%;
  text-align: center;
  padding: 5px 0px;
}
.wrap-budget .table-condensed td span {
  padding-right: 5px;
}
.wrap-budget .table-condensed td input[type="number"] {
  width: 110px;
  margin-right: 5px;
}

.wrap-budget .contentLeft .table-condensed td {
  text-align: center;
}
.wrap-budget .table-condensed td.height_row_contents {
  height: 32px;
}
.wrap-budget .table-content {
  height: calc(100vh - 350px);
  overflow-y: auto;
  position: relative;
  border-top: 5px solid #d2d6de;
}

.width_row_contents {
  width: 116px;
}
.wrap-budget .width_row_contents {
  width: 124px;
}

.scroll-bottom {
  margin-top: 5px;
  width: 100%;
}

.scroll-bottom .scroll {
  width: 1668px;
  height: 10px;
}

.scroll-bottom-bias-settings .scroll {
  width: 1200px;
  height: 10px;
}

#headRight::-webkit-scrollbar {
  display: none;
}

#contentRight::-webkit-scrollbar {
  display: none;
}
#headRight {
  width: calc(100% - 250px);
  overflow: auto;
}
#headRight table tr th {
  text-align: center;
}

.tooltip {
  position: relative;
}
.tooltip {
  display: block;
}

.tooltip-content {
  position: absolute;
  background-color: #000;
  text-align: center;
  color: #fff;
  transform: translateX(-50%);
  white-space: nowrap;
  border-radius: 4px;
  padding: 6px 10px;
  display: none;
}
.tooltip:hover .tooltip-content {
  display: block;
}

.tooltip-content:before {
  content: "";
  position: absolute;
}

/* POSITION TOP */
.tooltip-content.top {
  bottom: 100%;
  left: 50%;
  margin-bottom: 10px;
}
.tooltip-content.top:before {
  top: 100%;
  left: 50%;
  margin-left: -4px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
}
.icon-tooltip {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.icon-tooltip .tooltip p {
  margin: 0;
}

.width_row_contents input[type="number"] {
  height: 26px;
  font-size: 12px;
  line-height: 1.5;
  width: 97%;
}

#contentRight table tbody tr:nth-of-type(odd) {
  background: #fff;
}
#contentRight table tbody tr:nth-of-type(even) {
  background: linear-gradient(
    0deg,
    rgba(242, 242, 242, 0.9),
    rgba(242, 242, 242, 0.9)
  );
}
#contentRight table tbody tr.bg-gray {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 99.99%,
      rgba(255, 255, 255, 0) 100%
    ),
    #0d4796;
}
#contentRight input[disabled] {
  cursor: not-allowed;
  background: #fff;
  border: 1px solid #9e9898;
  height: 28px;
}

/* wrap-attend-monthly-list */

.wrap-attend-monthly-list {
  overflow-x: hidden;
  overflow-y: auto;
  color: #333333;
}

.wrap-attend-monthly-list .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrap-attend-monthly-list .table-condensed {
  border-collapse: collapse;
  background-color: "#f9f9f9";
}
.wrap-attend-monthly-list .table-condensed td,
.wrap-attend-monthly-list .table-condensed th {
  font-size: 14px;
  text-align: center;
}
.wrap-attend-monthly-list .contentLeft .table-condensed td {
  text-align: center;
}

.wrap-attend-monthly-list .table-content {
  max-height: 500px;
  overflow-y: auto;
  position: relative;
  overflow-y: auto;
}

.wrap-attend-monthly-list .padding-vertical-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.wrap-attend-monthly-list .headline-cell-width-60 {
  min-width: 60px;
  max-width: 60px;
}

.wrap-attend-monthly-list .headline-cell-width-70 {
  min-width: 70px;
  max-width: 70px;
}

.wrap-attend-monthly-list .headline-cell-width-80 {
  min-width: 80px;
  max-width: 80px;
}

.wrap-attend-monthly-list .headline-cell-width-90 {
  min-width: 90px;
  max-width: 90px;
}

.wrap-attend-monthly-list .headline-cell-width-95 {
  min-width: 95px;
  max-width: 95px;
}

.wrap-attend-monthly-list .headline-cell-width-110 {
  min-width: 110px;
  max-width: 110px;
}

.wrap-attend-monthly-list .headline-cell-width-120 {
  min-width: 120px;
  max-width: 120px;
}

.wrap-attend-monthly-list th.headline-cell-width-other-item,
.wrap-attend-monthly-list td.headline-cell-width-other-item {
  min-width: 130px;
  max-width: 130px;
}

.wrap-attend-monthly-list .head-line2-height {
  height: 65px;
}

.wrap-attend-monthly-list .headline-cell-width-150 {
  min-width: 150px;
  max-width: 150px;
}

#topRightAreaTime::-webkit-scrollbar,
#bottomRightAreaTime::-webkit-scrollbar {
  display: none;
}

#topRightAreaTime,
#bottomRightAreaTime {
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

#topRightAreaTime table tr th {
  text-align: center;
}

.wrap-attend-monthly-list .margin-bottom-0,
.wrap-attend-monthly-list .margin-bottom-0 {
  margin-bottom: 0px;
}

.wrap-attend-monthly-list .table-condensed td.height_row_contents {
  height: 32px;
}

.wrap-attend-monthly-list .head-line1-height {
  height: 31px;
}

.wrap-attend-monthly-list .head-line3-height {
  height: 99px;
}

.wrap-attend-monthly-list .head-line4-height {
  height: 72px;
}

.wrap-attend-monthly-list .content-scroll-bottom {
  margin-top: 5px;
  width: 100%;
}

.wrap-attend-monthly-list .content-scroll-bottom .scroll {
  height: 10px;
}

.wrap-attend-monthly-list table > thead > tr > th,
.wrap-attend-monthly-list table > tbody > tr > td {
  border-bottom: 1px #d3d3d3 solid;
  border-left: 1px #d3d3d3 solid;
}

.wrap-attend-monthly-list table > thead > tr > th {
  vertical-align: middle !important;
}

.text-center {
  text-align: center;
}

/*
*/
#formSalesReport {
  padding: 15px;
  font-size: 12px;
}

table > tbody > tr > td {
  vertical-align: middle !important;
}

.scroll-table {
  overflow: auto;
  white-space: nowrap;
  border: solid 1px #ddd;
}

#formSalesReport table {
  width: 100%;
}

#formSalesReport .overflow-hidden {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden;
}

#formSalesReport .clearfix {
  display: block;
  content: "";
  clear: both;
}

#formSalesReport #layoutSale_01 {
  margin-top: -20px;
}

#formSalesReport #layoutSale_02_buttom {
  height: calc(100vh - 300px);
  overflow-y: auto;
  position: relative;
  top: -20px;
  border-top: 5px solid #d2d6de;
}

#formSalesReport .layout_sale_right {
  overflow: auto;
  width: calc(100% - 350px);
}

#formSalesReport .layout_sale_left {
  border-right: 5px solid #d2d6de;
  width: 345px;
  float: left;
}

#formSalesReport .layout_sale_right_content {
  width: 3550px;
}

#formSalesReport #layout_sale_02_buttom_right_set_scroll {
  overflow: auto;
  height: 15px;
  margin-top: -35px;
}

#layout_sale_02_buttom_right_content_set_scroll {
  width: 3518px;
  height: 10px;
}

#formSalesReport tr > td {
  max-width: 100px;
  width: 100px;
  height: 31px;
}

#formSalesReport #searchYearMonth,
#formSalesReport organization_select span {
  height: 31px;
}

#formSalesReport .layout_sale_left tr > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#formSalesReport .height_row_plus {
  height: 62px;
}
#formSalesReport .table-bordered {
  border-collapse: collapse;
}
#formSalesReport .table-bordered > thead > tr > th,
#formSalesReport .table-bordered > tbody > tr > th,
#formSalesReport .table-bordered > tfoot > tr > th,
#formSalesReport .table-bordered > thead > tr > td,
#formSalesReport .table-bordered > tbody > tr > td,
#formSalesReport .table-bordered > tfoot > tr > td {
  border: 1px solid #222d32;
}

#formSalesReport td.day_hover:hover {
  background: #00c0ef !important;
}

#formSalesReport .padding_right_10 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 10px !important;
}

#formSalesReport .form-horizontal .control-label {
  padding-top: 0px;
  font-weight: inherit;
}

#formSalesReport .split_sale_repport {
  background: white;
}

#formSalesReport .days-column {
  cursor: pointer;
}

#formSalesReport .saturday {
  color: blue;
}

#formSalesReport .sunday {
  color: red;
}

#formSalesReport .stripe-color {
  background: #f6f6ff;
}

textarea::placeholder {
  opacity: 1;
}

/* bias_settings */
.bias-settings__top,
.bias-settings__bottom {
  display: flex;
}
.bias-settings__top .layout_sale_left {
  display: flex;
}
.bias-settings__bottom .layout_sale_left {
  width: 303px;
  margin-top: 0px;
  border-top: 5px solid #d3d6de;
  display: flex;
}

.bias-settings__top .layout_sale_left::after {
  content: "";
  border-right: 5px solid rgb(210, 214, 222);
  margin-left: -2px;
}
.bias-settings__bottom .layout_sale_left::after {
  content: "";
  border-right: 5px solid rgb(210, 214, 222);
}
.bias-settings__bottom .layout_sale_right {
  border-top: 5px solid rgb(210, 214, 222);
}
.bias-settings__bottom .layout_sale_right::-webkit-scrollbar {
  display: none;
}

.bias-settings__top table,
.bias-settings__bottom table {
  border-collapse: collapse;
}
.bias-settings__top .layout_sale_left table {
  height: 99%;
}
.bias-settings__top tr td {
  padding: 8px 0px;
  border: 1px solid #222d32;
  font-size: 85%;
  width: 120px;
}
.bias-settings__bottom .layout_sale_left tr td {
  text-align: center;
  border: 1px solid #222d32;
  font-size: 85%;
  padding: 8px 0px;
}

.bias-settings__top .layout_sale_right {
  max-width: 1200px;
  overflow: auto;
}
.bias-settings__top .layout_sale_right::-webkit-scrollbar {
  display: none;
}
.bias-settings__top .layout_sale_right table {
  width: 1200px;
  overflow: auto;
}
.sales_plan_daily-settings__bottom {
  max-height: 500px;
  border-top: 5px solid #d2d6de;
  overflow: hidden;
}
.sales_plan_daily-settings__bottom
  #daily_layout_sale_right_2::-webkit-scrollbar {
  display: none;
}
.sales_plan_daily-settings__bottom .layout_sale_right_content1 tr td {
  position: relative;
}
.sales_plan_daily-settings__bottom .layout_sale_right_content1 tr td span {
  width: 96px;
  position: absolute;
  right: 7px;
  top: 7px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sales_plan_daily-settings__bottom table {
  border-collapse: collapse;
  width: 100%;
}
.sales_plan_daily-settings__bottom table::-webkit-scrollbar {
  display: none;
}
.sales_plan_daily-settings__bottom table tr td {
  border: 1px solid #222d32;
  padding: 3px;
  height: 30px;
}
.sales_plan_daily-settings__bottom table tr td p {
  margin: 0;
}
.sales_plan_daily-settings__bottom table tr td:last-child span {
  justify-content: flex-end;
  padding-right: 5px;
  font-size: 14px;
}
.sales_plan_daily-settings__bottom table tr td span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.bias-settings__bottom .layout_sale_right {
  border-top: 5px solid rgb(210, 214, 222);
  max-width: 1200px;
  overflow: auto;
}

.bias-settings__bottom .layout_sale_right table {
  display: inline-block;
  width: 1200px;
  overflow: auto;
}
.bias-settings__bottom .layout_sale_right tr td {
  border: 1px solid #222d32;
  font-size: 85%;
  padding: 8px;
  text-align: right;
  position: relative;
  height: 20px;
}
.bias-settings__bottom .layout_sale_right tr td span {
  position: absolute;
  top: 7px;
  right: 7px;
}
.bias-settings__bottom input {
  height: 25px;
  margin-right: 3px;
}

.sales_plan_daily-settings__top {
  display: flex;
}
.sales_plan_daily-settings__top table {
  border-collapse: collapse;
}
.sales_plan_daily-settings__top .layout_sale_left {
  border-right: 5px solid rgb(210, 214, 222);
}
.sales_plan_daily-settings__top .layout_sale_right table thead tr td {
  padding: 2px;
}
.sales_plan_daily-settings__top
  .layout_sale_right
  table
  thead
  tr:nth-of-type(1) {
  height: 49px;
}

.sales_plan_daily-settings__top .layout_sale_right {
  overflow: auto;
}

.sales_plan_daily-settings__top .layout_sale_right tr th {
  padding: 4px;
  background: #fff !important;
}

.sales_plan_daily-settings__top .layout_sale_right tr th select {
  padding: 5px;
  height: 30px;
  width: 80px;
}
.sales_plan_daily-settings__top .red-day,
[data-is="sales_budget_monthly"] .red-day {
  color: red;
}
.sales_plan_daily-settings__top .saturday {
  color: blue;
}

.scroll-bottom-daily-settings .scroll {
  height: 10px;
  width: 3250px;
}
.sales_plan_daily-settings__top table tr td,
.sales_plan_daily-settings__top table tr th {
  border: 1px solid #222d32;
  font-size: 85%;
}
/* start .wrap-attend-stamp-list */
.wrap-attend-stamp-list {
  /* overflow-x: hidden;
  overflow-y: auto; */
  color: #333333;
}
.wrap-attend-stamp-list table thead {
  color: #666666;
}
/* .wrap-attend-stamp-list .table-condensed {
  border-collapse: collapse;
  background-color: "#DCDCDC";
} */
.wrap-attend-stamp-list .table-condensed td,
.wrap-attend-stamp-list .table-condensed th {
  font-size: 14px;
  text-align: center;
}
.wrap-attend-stamp-list .contentLeft .table-condensed td {
  text-align: center;
}

.wrap-attend-stamp-list .table-content {
  max-height: 500px;
  overflow-y: auto;
  position: relative;
  overflow-y: scroll;
}

.wrap-attend-stamp-list .bg-primary {
  color: #fff;
  background: #22aee5;
  border-radius: 3px;
}

.wrap-attend-stamp-list .head-line4-height {
  height: 70px;
}
.wrap-attend-stamp-list .content-scroll-bottom .scroll {
  height: 10px;
}
.wrap-attend-stamp-list .table-condensed,
.wrap-attend-stamp-list .table-bordered {
  border: 1px solid #dcdcdc;
  border-spacing: 0;
  border-collapse: collapse;
}
.wrap-attend-stamp-list .table-bordered th,
.wrap-attend-stamp-list .table-bordered td {
  text-align: center;
}
.wrap-attend-stamp-list .table-bordered > thead > tr > th,
.wrap-attend-stamp-list .table-bordered > tbody > tr > th,
.wrap-attend-stamp-list .table-bordered > tfoot > tr > th,
.wrap-attend-stamp-list .table-bordered > thead > tr > td,
.wrap-attend-stamp-list .table-bordered > tbody > tr > td,
.wrap-attend-stamp-list .table-bordered > tfoot > tr > td {
  border: 1px solid #dcdcdc;
}
.wrap-attend-stamp-list .table-responsive {
  overflow-x: auto;
  margin-left: 16px;
  margin-right: 16px;
}
.table-responsive {
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #ddd;
}
.wrap-attend-stamp-list .table-condensed > tbody > tr > td,
.wrap-attend-stamp-list .table-condensed > tbody > tr > th,
.wrap-attend-stamp-list .table-condensed > tfoot > tr > td,
.wrap-attend-stamp-list .table-condensed > tfoot > tr > th,
.wrap-attend-stamp-list .table-condensed > thead > tr > td,
.wrap-attend-stamp-list .table-condensed > thead > tr > th {
  padding: 0 5px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.wrap-attend-stamp-list .head-line1-height {
  height: 41px;
}
/*
*/
#formSalesReport {
  padding: 15px;
  font-size: 12px;
}

table > tbody > tr > td {
  vertical-align: middle !important;
  position: relative;
}

/* table > thead > tr > th {
  vertical-align: middle !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fdfbfb),
    to(#f5f5f5)
  ) !important;
  background: -webkit-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: -moz-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: -o-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: linear-gradient(to bottom, #fdfbfb, #f5f5f5) !important;
} */

.scroll-table {
  overflow: auto;
  white-space: nowrap;
  border: solid 1px #ddd;
}

table {
  margin: 0;
  border: none;
}

#formSalesReport table {
  width: 100%;
}

#formSalesReport .overflow-hidden {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden;
}

#formSalesReport .clearfix {
  display: block;
  content: "";
  clear: both;
}

#formSalesReport #layoutSale_01 {
  margin-top: -20px;
}

#formSalesReport #layoutSale_02_buttom {
  height: calc(100vh - 300px);
  overflow-y: auto;
  position: relative;
  top: 0px;
  border-top: 5px solid #d2d6de;
}

#formSalesReport .layout_sale_right {
  overflow: auto;
  width: calc(100% - 350px);
}

#formSalesReport .layout_sale_left {
  border-right: 5px solid #d2d6de;
  width: 345px;
  float: left;
}

#formSalesReport .layout_sale_right_content {
  width: 3560px;
}

#formSalesReport #layout_sale_02_buttom_right_set_scroll {
  overflow: auto;
  height: 15px;
  margin-top: -17px;
}

#layout_sale_02_buttom_right_content_set_scroll {
  width: 3518px;
  height: 10px;
}

#formSalesReport tr > td {
  max-width: 100px;
  width: 100px;
  height: 31px;
}

#formSalesReport #searchYearMonth,
#formSalesReport organization_select span {
  height: 31px;
}

#formSalesReport .layout_sale_left tr > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#formSalesReport .height_row_plus {
  height: 62px;
}
#formSalesReport .table-bordered {
  border-collapse: collapse;
}
#formSalesReport .table-bordered > thead > tr > th,
#formSalesReport .table-bordered > tbody > tr > th,
#formSalesReport .table-bordered > tfoot > tr > th,
#formSalesReport .table-bordered > thead > tr > td,
#formSalesReport .table-bordered > tbody > tr > td,
#formSalesReport .table-bordered > tfoot > tr > td {
  border: 1px solid #222d32;
}

#formSalesReport td.day_hover:hover {
  background: #00c0ef !important;
}

#formSalesReport .padding_right_10 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 10px !important;
}

#formSalesReport .form-horizontal .control-label {
  padding-top: 0px;
  font-weight: inherit;
}

#formSalesReport .split_sale_repport {
  background: white;
}

#formSalesReport .days-column {
  cursor: pointer;
}

#formSalesReport .saturday {
  color: blue;
}

#formSalesReport .sunday {
  color: red;
}

#formSalesReport .stripe-color {
  background: #f6f6ff;
}

textarea::placeholder {
  opacity: 1;
}

.text-right {
  text-align: right;
}

.overflow-hidden {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden;
}

.clearfix {
  display: block;
  content: "";
  clear: both;
}

#SalesDailyReport {
  padding: 15px;
  font-size: 12px;
}

#SalesDailyReport #layoutSale_02_buttom {
  height: calc(100vh - 410px);
  overflow-y: auto;
  position: relative;
  border-top: 5px solid #d2d6de;
}

#SalesDailyReport .layout_sale_right {
  overflow: auto;
  width: calc(100% - 350px);
}

#SalesDailyReport .layout_sale_left {
  border-right: 5px solid #d2d6de;
  width: 340px;
  float: left;
}

#SalesDailyReport .layout_sale_right_content {
  width: 850px;
}

#SalesDailyReport #layout_sale_02_buttom_right_set_scroll {
  overflow: auto;
  height: 15px;
  margin-top: -17px;
  float: right;
}

#layout_sale_02_buttom_right_content_set_scroll_daily {
  width: 818px;
  height: 10px;
}

#SalesDailyReport tr > td {
  max-width: 100px;
  width: 100px;
  white-space: initial;
  height: 27px;
}

#SalesDailyReport .height_row_plus {
  height: 56px !important;
}

#SalesDailyReport .table-bordered {
  border-collapse: collapse;
  width: 100%;
}

#SalesDailyReport .table-bordered > thead > tr > th,
#SalesDailyReport .table-bordered > tbody > tr > th,
#SalesDailyReport .table-bordered > tfoot > tr > th,
#SalesDailyReport .table-bordered > thead > tr > td,
#SalesDailyReport .table-bordered > tbody > tr > td,
#SalesDailyReport .table-bordered > tfoot > tr > td {
  border: 1px solid #222d32;
  height: 27px;
}

#SalesDailyReport td.day_hover:hover {
  background: #00c0ef !important;
}

#SalesDailyReport .form-horizontal .control-label {
  padding-top: 0px;
  font-weight: inherit;
}
#SalesDailyReport .edit_yellow {
  background-color: yellow;
}

#SalesDailyReport .edited_red {
  background-color: red;
}

textarea::placeholder {
  opacity: 1;
}

#SalesDailyReport textarea {
  width: 100%;
  margin-top: 15px;
  padding: 10px;
}

.table-report-month {
  margin-top: 15px;
}

.table-report-month tfoot tr th {
  text-align: right;
}
.table-report-month tfoot tr th:first-child {
  text-align: center;
}

.table-report-month tr th,
.table-report-month tr td {
  border: 1px solid #222d32;
  font-weight: normal;
  font-size: 14px;
  padding: 7px;
  white-space: nowrap;
}

.table-report-month tr th {
  text-align: center;
}

.table-report-month tr td {
  text-align: right;
}

.table-report-month tr td:nth-of-type(1),
.table-report-month tr td:nth-of-type(2) {
  text-align: center;
}
.table-report-month tr td.text-right {
  text-align: right;
}
.table-time_day_separate_sales tbody tr td:first-child {
  width: 150px;
  white-space: nowrap;
}
.table-time_day_separate_sales tr th {
  white-space: nowrap;
}
.table-time_day_separate_sales tbody tr td {
  height: 20px;
  width: 50px;
  white-space: nowrap;
}

/* Table fixed */
.wrap_time_day_separate_sales .contentHead .tblLeft {
  width: 187px;
  position: relative;
  border: 1px solid #222d32;
  background-color: #f9f8f8;
}
.wrap_time_day_separate_sales .contentHead .tblLeft table tr th {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}
.wrap_time_day_separate_sales .contentHead .tblRight table tr th {
  white-space: nowrap;
  border: 1px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
}
.wrap_time_day_separate_sales
  .contentHead
  .tblRight
  table
  tr
  th:nth-of-type(1) {
  border-left: 0px;
}

.wrap_time_day_separate_sales .contentHead .tblRight {
  width: calc(100% - 194px);
}

.wrap_time_day_separate_sales .contentHead .tblRight table {
  border-collapse: collapse;
  width: 100%;
}

.wrap_time_day_separate_sales .contentTable {
  overflow: auto;
}
.wrap_time_day_separate_sales .contentTable .tblLeft {
  border-top: 0px;
  overflow: auto;
  width: 189px;
  overflow-y: hidden;
}
.wrap_time_day_separate_sales .contentTable .tblLeft::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}
.wrap_time_day_separate_sales .contentTable .tblLeft tr:first-child td {
  border-top: 0px;
}

.wrap_time_day_separate_sales .contentTable .tblLeft table {
  border-collapse: collapse;
  width: 100%;
}
.wrap_time_day_separate_sales .contentTable .tblLeft table tr td {
  white-space: nowrap;
  font-weight: normal;
  border: 1px solid #222d32;
  padding: 7px 20px;
  font-size: 14px;
}
.wrap_time_day_separate_sales .contentHead .tblRight {
  overflow: auto;
}
.wrap_time_day_separate_sales .contentHead .tblRight::-webkit-scrollbar {
  display: none;
}
.wrap_time_day_separate_sales .contentTable .tblRight {
  overflow: auto;
  width: calc(100% - 194px);
  overflow-y: hidden;
}
.wrap_time_day_separate_sales .contentTable .tblRight table {
  border-collapse: collapse;
  width: 100%;
}

.wrap_time_day_separate_sales .contentTable .tblRight table tr td {
  white-space: nowrap;
  font-weight: normal;
  border: 1px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
  position: relative;
  border-left: 0;
  border-top: 0;
  height: 20px;
}

.wrap_time_separate_products_sales .contentHead .tblRight table tr th {
  padding: 7px 5px !important;
}
.wrap_time_separate_products_sales .contentTable .tblRight table tr td span {
  top: 7px;
  width: 100px !important;
  display: block;
}

.wrap_time_day_separate_sales .contentTable .tblRight table tr {
  height: 32px;
}

.wrap_time_day_separate_sales .contentTable .tblRight table tr td span {
  width: 120px;
  display: block;
}
.wrap_time_day_separate_sales .contentTable .tblRight::-webkit-scrollbar {
  display: none;
}
.wrap_time_day_separate_sales .scroller {
  display: flex;
}
.wrap_time_day_separate_sales .scrollLeft {
  width: 189px;
}
.wrap_time_day_separate_sales .scrollRight {
  width: calc(100% - 189px);
  float: right;
  height: 24px;
  overflow: auto;
}
.wrap_time_day_separate_sales .scrollRight .scrollAction {
  width: 1612px;
  height: 10px;
}

/* wrap_time_separate_sales */
.wrap_time_separate_sales .contentHead .tblLeft {
  width: 448px;
  position: relative;
  border: 1px solid #222d32;
  background-color: #f9f8f8;
}
.wrap_time_separate_sales .contentHead .tblLeft {
  display: flex;
  align-items: center;
}
.wrap_time_separate_sales .contentHead .tblLeft table {
  width: 100%;
  border-collapse: collapse;
}
.wrap_time_separate_sales .contentHead .tblLeft table tr th {
  white-space: nowrap;
  width: calc(100% / 3);
  height: 67px;
  border-right: 1px solid;
  font-size: 14px;
}

.wrap_time_separate_sales .contentHead .tblLeft table tr th:last-child {
  border-right: 0px;
}

.wrap_time_separate_sales .contentHead .tblRight table tr th {
  white-space: nowrap;
  border: 1px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
}
.wrap_time_separate_sales .contentHead .tblRight table tr th:nth-of-type(1) {
  border-left: 0px;
}

.wrap_time_separate_sales .contentHead .tblRight {
  width: calc(100% - 400px);
}

.wrap_time_separate_sales .contentHead .tblRight table {
  border-collapse: collapse;
}

.wrap_time_separate_sales .contentTable {
  overflow: auto;
}
.wrap_time_separate_sales .contentTable .tblLeft {
  border-top: 0px;
  overflow: auto;
  width: 189px;
  overflow-y: hidden;
}
.wrap_time_separate_sales .contentTable .tblLeft::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}
.wrap_time_separate_sales .contentTable .tblLeft tr:first-child td {
  border-top: 0px;
}

.wrap_time_separate_sales .contentTable .tblLeft table {
  border-collapse: collapse;
}
.wrap_time_separate_sales .contentTable .tblLeft table tr td {
  white-space: nowrap;
  font-weight: normal;
  border: 1px solid #222d32;
  padding: 7px;
  font-size: 14px;
}
.wrap_time_separate_sales .contentHead .tblRight {
  overflow: auto;
}
.wrap_time_separate_sales .contentHead .tblRight::-webkit-scrollbar {
  display: none;
}
.wrap_time_separate_sales .contentTable .tblRight {
  overflow: auto;
  width: calc(100% - 189px);
  overflow-y: hidden;
}
.wrap_time_separate_sales .contentTable .tblRight table {
  border-collapse: collapse;
  width: 100%;
}

.wrap_time_separate_sales .contentTable .tblRight table tr td {
  white-space: nowrap;
  font-weight: normal;
  border: 1px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
  position: relative;
  border-left: 0;
  height: 20px;
  border-top: 0;
}

.wrap_time_separate_sales .contentTable .tblRight table tr {
  height: 32px;
}

.wrap_time_separate_sales .contentTable .tblRight table tr td span {
  width: 100px;
}
.wrap_time_separate_sales .contentTable .tblRight::-webkit-scrollbar {
  display: none;
}
.wrap_time_separate_sales .scroller {
  display: flex;
}
.wrap_time_separate_sales .scrollLeft {
  width: 450px;
}
.wrap_time_separate_sales .scrollRight {
  width: calc(100% - 450px);
  float: right;
  height: 24px;
  overflow: auto;
}
.wrap_time_separate_sales .scrollRight .scrollAction {
  width: 1612px;
  height: 10px;
}
#tblNotFound {
  width: 100%;
  border: 1px solid;
  border-top: 0px;
  text-align: center;
}
#tblNotFound::-webkit-scrollbar {
  display: none;
}

#daily_layout_sale_left table tr td:last-child {
  width: 85px;
}

.wrap-budget #contentRight tr td {
  text-align: right;
}
.wrap_time_separate_products_sales .tbl_fixcolumn tr td:nth-of-type(1) {
  width: 154px !important;
  position: relative;
}

.wrap_time_separate_products_sales .tbl_fixcolumn tr td:nth-of-type(1) span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.tbl_fixcolumn tr td:nth-of-type(1) {
  width: 100px !important;
}

.tbl_fixcolumn tr td:nth-of-type(2) {
  width: 300px !important;
}
.tbl_fixcolumn tr td:nth-of-type(3) {
  width: 100px !important;
}

/* wrap_sales_management_table */
.wrap_sales_management_table .contentHead .tblLeft {
  width: 323px;
}
.wrap_sales_management_table tr th {
  position: relative !important;
  transform: translate(0) !important;
  left: 0 !important;
  top: 0 !important;
}

.wrap_sales_management_table .contentHead .tblLeft table {
  border-collapse: collapse;
  width: 100%;
}
.wrap_sales_management_table .contentHead .tblRight {
  width: calc(100% - 325px);
}
.wrap_sales_management_table table .contentHead table tr th span {
  position: absolute;
  top: 0;
  left: 0;
}

.wrap_sales_management_table .contentHead .tblLeft tr th:nth-of-type(1) {
  border-right: 1px solid;
  height: 67px;
  width: 159px;
}

.wrap_sales_management_table .contentTable .tblLeft {
  width: 325px;
  float: left;
}
.wrap_sales_management_table .contentTable .tblLeft table {
  width: 100%;
  text-align: center;
}
.wrap_sales_management_table .contentTable .tblRight {
  width: calc(100% - 325px);
  float: right;
}
.wrap_sales_management_table .contentHead .tblRight table tr th {
  padding: 7px;
}
.wrap_sales_management_table .contentTable .tblRight table tr td {
  padding: 7px;
}
.wrap_sales_management_table .contentHead .tblRight table tr th span {
  width: 100px;
  display: block;
}
.wrap_sales_management_table .contentTable .tblRight table {
  width: 100%;
}
.wrap_sales_management_table .contentTable .tblRight table tr td span {
  width: 120px;
  display: block;
  position: relative;
  text-align: right;
  top: 0;
  left: 0;
}
.wrap_sales_management_table
  .contentTable
  .tblRight
  table
  tr
  td.text-center
  span {
  text-align: center;
}

.wrap_sales_management_table
  .contentTable
  .tblRight
  table
  tr
  td
  span.text-center {
  text-align: center;
}
.wrap_sales_management_table table {
  font-size: 14px;
}
.wrap_sales_management_table .contentTable .tblLeft table tr td {
  padding: 7px 17px;
}
.wrap_sales_management_table .contentTable {
  max-height: 600px;
}

/* wrap_monthly_day_separate_sales_management */
.wrap_monthly_day_separate_sales_management .contentHead .tblRight table tr th {
  padding: 7px 5px;
}
.wrap_monthly_day_separate_sales_management .contentHead .tblRight tr th span {
  width: 50px;
  display: flex;
  justify-content: center;
}

.wrap_monthly_day_separate_sales_management
  .contentTable
  .tblRight
  table
  tr
  td {
  padding: 7px 5px;
}
.wrap_monthly_day_separate_sales_management
  .contentTable
  .tblRight
  table
  tr
  td
  span {
  left: 4px;
}

.thead-bottom {
  bottom: -10px;
  top: auto !important;
  border-top: 1px solid #222d32;
  width: 99%;
  background: transparent !important;
  padding-top: 8px;
  font-size: 14px;
}
.wrap_time_day_separate_sales .contentHead .tblLeft table tr th {
  background: transparent !important;
}

.wrap_time_day_separate_sales .contentFooter {
  display: flex;
}
.wrap_time_day_separate_sales .contentFooter .tblLeft {
  width: 189px;
}
.wrap_time_day_separate_sales .contentFooter .tblLeft table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}
.wrap_time_day_separate_sales .contentFooter .tblLeft table tr th {
  border: 1px solid;
  height: 37px;
}
.wrap_time_day_separate_sales .contentFooter .tblRight {
  width: calc(100% - 194px);
  overflow: auto;
}
.wrap_time_day_separate_sales .contentFooter .tblRight::-webkit-scrollbar {
  display: none;
}

.wrap_time_day_separate_sales .contentFooter .tblRight tr th {
  padding: 7px 5px;
  height: 25px;
  border: 1px solid;
  position: relative;
  width: 120px;
}
.wrap_time_day_separate_sales .contentFooter .tblRight table {
  border-collapse: collapse;
  font-size: 14px;
}
.wrap_time_day_separate_sales .contentFooter .tblRight tr th span {
  width: 120px;
  display: block;
}
.wrap_time_day_separate_sales .contentFooter .tblRight tr th:nth-of-type(1) {
  border-left: 0px;
}

.wrap_sales_management_table_abc table {
  border-collapse: collapse;
  width: 100%;
}
.wrap_sales_management_table_abc tr th,
.wrap_sales_management_table_abc tr td {
  padding: 7px 17px;
  border: 1px solid;
  position: relative;
  width: 100px;
  height: 25px;
  font-size: 14px;
}

.wrap_sales_management_table_abc tr th span,
.wrap_sales_management_table_abc tr td span {
  width: 100%;
  display: block;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
}
.wrap_sales_management_table_abc .tblRight table thead tr th:nth-of-type(1) {
  border-left: 0px;
}

.wrap_sales_management_table_abc tr td.not_found_item {
  border-top: 0px;
  border-bottom: 0px;
}
.wrap_sales_management_table_abc .contentFooter {
  display: flex;
}
.wrap_sales_management_table_abc .contentFooter .tblLeft {
  width: 650px;
  position: relative;
}

.wrap_sales_management_table_abc .contentTable {
  display: flex;
  font-size: 14px;
  overflow: hidden;
}

.wrap_sales_management_table_abc .contentTable .tblLeft {
  width: 650px;
  position: relative;
}
.wrap_sales_management_table_abc .contentTable .tblLeft tr td {
  border-top: 0px;
}
.wrap_sales_management_table_abc .contentTable .tblLeft tr td span {
  position: relative;
  transform: translate(-50%, 0%);
  left: 50%;
  width: 100%;
}

.wrap_sales_management_table_abc .contentHead .tblLeft tr th:nth-of-type(1) {
  width: 15%;
}

.wrap_sales_management_table_abc .contentHead .tblLeft tr th:nth-of-type(2) {
  width: 15%;
}

.wrap_sales_management_table_abc .contentHead .tblLeft tr th:nth-of-type(3) {
  width: 30%;
}
.wrap_sales_management_table_abc .contentHead .tblLeft tr th:nth-of-type(4) {
  width: 40%;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td:nth-of-type(1) {
  width: 15%;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td:nth-of-type(2) {
  width: 15%;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td:nth-of-type(3) {
  width: 30%;
}
.wrap_sales_management_table_abc .contentTable .tblLeft tr td:nth-of-type(4) {
  width: 40%;
}
.wrap_sales_management_table_abc .contentTable .tblRight tr td {
  border-top: 0px;
}

.wrap_sales_management_table_abc .contentTable .tblRight {
  width: calc(100% - 650px);
  overflow: auto;
}
.wrap_sales_management_table_abc .contentTable .tblRight::-webkit-scrollbar {
  display: none;
}

.wrap_sales_management_table_abc .contentTable .tblRight tr td span {
  width: 126px;
}
.wrap_sales_management_table_abc .contentTable .tblRight tr td span.text-right {
  text-align: right;
}

.wrap_sales_management_table_abc .contentHead .tblRight tr th span {
  width: 126px;
}

.wrap_sales_management_table_abc .contentTable .tblRight tr td:nth-of-type(1) {
  border-left: 0px;
}
.wrap_sales_management_table_abc .contentTable {
  overflow: hidden;
  font-size: 14px;
}
.wrap_sales_management_table_abc .contentHead {
  display: flex;
}

.wrap_sales_management_table_abc .contentHead .tblLeft {
  width: 650px;
  position: relative;
}
.wrap_sales_management_table_abc .contentHead .tblRight {
  width: calc(100% - 650px);
  overflow: hidden;
}
.wrap_sales_management_table_abc .contentFooter .tblRight {
  width: calc(100% - 650px);
  overflow: auto;
}
.wrap_sales_management_table_abc .contentFooter .tblRight tr th:nth-of-type(1) {
  border-left: 0px;
}

.wrap_sales_management_table_abc table tr td {
  border-top: 0px;
}

.wrap_sales_management_table_abc .contentFooter .tblRight tr th span {
  width: 126px;
}
.wrap_sales_management_table_abc .contentTable tr:last-child td {
  border-bottom: 0px;
}

/* wrap_sales_management_table_cnt_order_abc */
.wrap_sales_management_table_cnt_order_abc .contentTable .tblRight tr td span {
  white-space: nowrap;
}

.wrap_time_separate_sales
  .contentTable
  .tblLeft
  .column-width-fixed
  tr
  td:nth-of-type(1) {
  width: calc(25% - 1px) !important;
  height: 20px;
}
.wrap_time_separate_sales
  .contentTable
  .tblLeft
  .column-width-fixed
  tr
  td:nth-of-type(2) {
  width: 55% !important;
}
.wrap_time_separate_sales
  .contentTable
  .tblLeft
  .column-width-fixed
  tr
  td:nth-of-type(3) {
  width: 20% !important;
}
.wrap_time_separate_sales
  .contentHead
  .tblLeft
  .column-width-fixed
  tr
  th:nth-of-type(1) {
  width: calc(25% - 1px) !important;
}
.wrap_time_separate_sales
  .contentHead
  .tblLeft
  .column-width-fixed
  tr
  th:nth-of-type(2) {
  width: 55% !important;
}
.wrap_time_separate_sales
  .contentHead
  .tblLeft
  .column-width-fixed
  tr
  th:nth-of-type(3) {
  width: 20% !important;
}
.wrap_time_day_separate_sales .contentHead .tblRight table tr th span {
  width: 120px;
  display: block;
}
.wrap_time_separate_sales .contentHead .tblRight table tr th span {
  width: 100px;
  display: block;
}
#scrollTableNotFound::-webkit-scrollbar {
  display: none;
}

.input-search input {
  border: none;
  outline: none;
}

.filter-number-item button.dropdown-toggle {
  border: 1px solid rgb(220, 220, 220) !important;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: #fff;
  outline: none;
  width: 68px;
}
.pagination-custom ul {
  list-style: none;
  display: flex;
  padding-left: 0px;
  align-items: center;
  margin: 0;
}
.pagination-custom li {
  padding: 7px 15px;
}
.pagination-custom li a {
  text-decoration: none;
  color: #333;
  outline: none;
}
.pagination-custom li.active {
  background: #f8f8f8;
  border-radius: 3px;
}
.dropdown-menu .dropdown-item {
  display: block;
  padding: 8px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  left: 2px;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #f2f5f9;
}

#dropdown-basic {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
}
.dropdown-menu.show {
  width: 67px;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  z-index: 1;
}
/* stepper */
.stepper-container {
  padding: 30px;
  margin-top: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .stepper-container {
    max-width: 667px;
    margin: 0 auto;
  }
  .stepper-container .stepper-item .stepper-title {
    font-size: 12px !important;
  }
}
.stepper-container .stepper-item .stepper-title {
  transform: translate(-50%, -50%) !important;
  left: 50%;
  top: -15px;
  font-size: 12px;
  min-width: unset !important;
}
.stepper-item-outer:nth-of-type(odd) {
  width: 14px !important;
  height: 14px !important;
  border: 0px !important;
}
.stepper-item-inner-active {
  background-color: transparent;
}
.stepper-item-outer:nth-of-type(odd)
  .stepper-item-inner.stepper-item-inner-active {
  width: 14px !important;
  height: 14px !important;
}
.stepper-item-outer:nth-of-type(odd)
  .stepper-item-inner.stepper-item-inner-active:before {
  content: "";
  position: absolute;
  height: 14px;
  left: -1px;
  width: 14px;
  display: block;
  background: #007bc3;
  border-radius: 50%;
  top: 0px;
  z-index: 1;
}
.stepper-item-outer:nth-of-type(odd)
  .stepper-item-inner.stepper-item-inner-active:after {
  content: "";
  position: absolute;
  height: 20px;
  left: -4px;
  width: 20px;
  display: block;
  background: #fff;
  border-radius: 50%;
  top: -3px;
  box-shadow: 0px 0px 5px rgba(102, 102, 102, 0.3);
}
.stepper-item-inner-completed {
  background: #007bc3;
}
.stepper-item.active .stepper-title {
  color: #007bc3;
  line-height: 14px;
  font-size: 12px;
}
.stepper-container .stepper-item .stepper-title-active {
  color: #007bc3;
  font-size: 12px;
  line-height: 14px;
}
.stepper-item-outer:nth-of-type(even).active {
  border: 0.5px solid #007bc2;
}

/* Calendar */
.icf-calendar {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0%, -50%);
}
.calendar {
  z-index: 99999;
  position: fixed;
  top: 36px;
  left: 0;
  width: 294px;
  background-color: #f8f8f8;
  color: #666;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0px 4px 20px rgba(102, 102, 102, 0.3);
}
.calendar *,
.calendar *::before,
.calendar *::after {
  box-sizing: inherit;
}
.calendar__arrow {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.calendar__arrow::before {
  display: inline-block;
  border: solid;
  border-width: 0 1px 1px 0;
  content: "";
  border-color: inherit;
  padding: 3px;
}
.calendar__arrow--right::before {
  transform: rotate(-45deg);
}
.calendar__arrow--left::before {
  transform: rotate(135deg);
}
.calendar__year,
.calendar__month {
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  align-items: center;
  cursor: pointer;
}
.calendar__year {
  padding: 16px 8px 8px 8px;
}
.calendar__month {
  padding: 8px 8px 16px 8px;
}
.calendar__year-value,
.calendar__month-value {
  line-height: 16px;
  font-size: 18px;
}
.calendar__dow,
.calendar__day {
  display: flex;
}
.calendar__day {
  background-color: #fff;
  flex-wrap: wrap;
  padding: 0 8px 16px;
}
.calendar__dow {
  background-color: #f2f8fc;
  height: 40px;
  padding: 0 8px;
}
.calendar__dow-item,
.calendar__day-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-size: 12px;
  width: 14.2857%;
  height: 34px;
  margin-top: 3px;
  cursor: pointer;
}
.calendar__dow-item--today .calendar__day-item-value,
.calendar__day-item--today .calendar__day-item-value {
  background-color: #e7f1fd;
  color: #007bc3;
  border-radius: 50%;
}
.calendar__day-item-value {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar__dow-item:first-of-type {
  color: #fc9c95;
}
.calendar__dow-item:last-child {
  color: #45d1d1;
}

.table-bordered {
  border-collapse: collapse;
}

.table-bordered tr th,
.table-bordered tr td {
  border: 1px solid #dcdcdc;
  padding: 10px;
  background: #fff !important;
  font-size: 14px;
}
.table-bordered tr td:first-child,
.table-bordered tr th:first-child {
  border-left: 1px solid transparent;
}
.table-bordered tr td:last-child,
.table-bordered tr th:last-child {
  border-right: 1px solid transparent;
}

.table-bordered tr th {
  font-weight: bold;
  color: #666666;
}

.table-bordered tr td {
  color: #333333;
}
table thead tr th {
  font-weight: bold;
  color: #666666;
  background: transparent !important;
}

table tr td {
  color: #333333;
}

table.table-no-border tr th,
table.table-no-border tr td {
  border: none !important;
  padding: 0px;
  text-align: left;
}
.print:hover svg path,
.print:hover span,
.timeRecorder:hover svg path,
.timeRecorder:hover span {
  fill: #333333;
  color: #333333;
}

.table-employment-domain-list table tr th:nth-child(5) {
  width: 180px;
}
@media only screen and (max-width: 1024px) {
  .table-stamp-history tr th:nth-of-type(1) {
    width: 130px !important;
  }
}

table tr.hightlight td {
  z-index: 2;
}
table tr.hightlight td:after {
  content: "";
  background-color: rgba(85, 206, 253, 0.4) !important;
  animation: myfadeIn 3s ease-in-out;
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
}
.hightlight > div {
  position: relative;
  z-index: 2;
}

.hightlight > div:after {
  content: "";
  background-color: rgba(85, 206, 253, 0.4) !important;
  animation: myfadeIn 3s ease-in-out;
  position: absolute;
  left: 0;
  height: inherit;
  z-index: 1;
  width: 100%;
}

.rct-sidebar .hightlight {
  position: relative;
  z-index: 2;
}

.rct-sidebar .hightlight:after {
  content: "";
  background-color: rgba(85, 206, 253, 0.4) !important;
  animation: myfadeIn 3s ease-in-out;
  position: absolute;
  left: 0;
  height: inherit;
  z-index: 1;
  width: 100%;
  top: 0;
}

@keyframes myfadeIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.tbl-salary-download {
  table-layout: auto !important;
}
.tbl-salary-download tr td {
  text-align: center;
}
.tbl-salary-download tr th:last-child {
  width: 50px;
}

.table-scroll .table-container {
  max-height: 600px;
}

.table-scroll .table-container table thead tr th:after {
  content: "";
  width: 100%;
  position: absolute;
  border-top: 1px solid #dcdcdc !important;
  top: 0;
  left: 0;
}

.table-scroll .table-container table thead tr th:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
}
