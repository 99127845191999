.slideIn {
  animation-name: side-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.slideOut {
  animation-name: side-out;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes side-in {
  from {
    right: -232px;
  }
  to {
    right: 0px;
  }
}

@keyframes side-out {
  from {
    right: 0px;
  }
  to {
    right: -232px;
  }
}
