.monthly-shift {
  width: 100%;

  .fixed-left {
    width: 160px;
    float: left;
    overflow-x: hidden;
  }
  .scrollable-right {
    float: left;
    overflow-x: scroll;
    width: 950px;
  }
}

.table__monthly-shift {
  border-spacing: 0 !important;

  .cell__itemWrapper {
    .calendar-item:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.freeze-col {
  background: white;
  position: sticky;
  left: 0;
  z-index: 1;
  // box-shadow: 1px 0 0 -1px #dcdcdc, -1px 0 0 -1px #dcdcdc;
}

.table__monthly-shift tbody {
  .freeze-col::after {
    content: "";
    top: 0;
    background: #dcdcdc;
    width: 1px;
    height: 100%;
    left: -1px;
    position: absolute;
  }
  .freeze-col::before {
    content: "";
    top: 0;
    background: #dcdcdc;
    width: 1px;
    height: 100%;
    right: -1px;
    position: absolute;
  }
}

#table-header::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.table__monthly-shift thead {
  tr:first-child > th {
    position: sticky;
    top: -1px;
    z-index: 82;
    border-top: 0;
    border-bottom: 0;
    box-shadow: inset 0 -1px 0 #dcdcdc;
  }
  tr:first-child > th:last-child {
    box-shadow: inset 0 -1px 0 #dcdcdc;
  }
  tr:first-child > th:nth-last-child(2) {
    z-index: 10;
  }
  tr > th:first-child,
  tr:first-child > th:nth-child(2) {
    z-index: 83;
  }
  tr:first-child > th:first-child {
    border-left: 0;
    box-shadow: inset -1px 0 0 #dcdcdc;
  }
  tr:nth-child(2) > th:first-child:after,
  tr:first-child > th:nth-child(2):after {
    width: 1px;
    height: 100%;
    content: "";
    background-color: #dcdcdc;
    position: absolute;
    right: -1px;
    top: 0;
  }
  .custom-position-dropdown[style] {
    transform: translate(80px, 35px) !important;
  }
  tr:last-child > th {
    top: 27px;
    position: sticky;
    z-index: 82;
    border-top: 0;
    border-bottom: 0;
    box-shadow: inset 0 -1px 0 #dcdcdc;
  }
  tr:last-child > th:nth-child(2) {
    z-index: 10;
  }
}
.table__monthly-shift tbody {
  tr:first-child td {
    border-top: 0;
  }
  tr > td:first-child {
    border-left: 0;
    border-right: 0;
  }
}

.border-l-none {
  border-left: none !important;
}
.border-r-none {
  border-right: none !important;
}
.border-b-none {
  border-bottom: none !important;
}
.border-b-white {
  border-bottom: 1px solid white !important;
}
.border-t-none {
  border-top: none !important;
}

.box-shadow-b-none {
  box-shadow: none !important;
}

#main-table {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  margin-left: 16px;
  margin-right: 16px;
}
