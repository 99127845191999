
.picker-panel {
  position: absolute;
  top: 42px;
  width: 240px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transition: all 0.3s;
  width: 300px;
  background-color: #f8f8f8;
  color: #666;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 4px 20px rgb(102 102 102 / 30%);
  font-family: inherit;
  border: 0;
}

/* INPUT */

.input-wrapper {
  position: relative;
  height: 100%;
  min-width: 85px;
  display: flex;
  align-items: center;
}

.imput-wrapper-selected {
}

.year-input {
  border: 0;
  width: 55px;
  cursor: auto;
  outline: 0;
  height: 100%;
  background: #fff;
  padding: 0 0 0 8px;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  color: #333333;
}

.css-1jk4dqd-DatePicker {
  padding: 5px 8px !important;
}

.react-datepicker
{
  font-size: 14px !important;
}
.input-icon {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  color: #333333;
  cursor: pointer;
  transition: all 2s;
}

.input-icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-icon-close {
  display: none;
}

.imput-wrapper-selected:hover > .input-icon-close {
  display: block;
  color: #1890ff;
}

/* YEAR PICKER */

.year-picker {
  position: relative;
  outline: none;
  width: 85px;
  list-style: none;
  font-size: 14px;
  text-align: left;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  background-clip: padding-box;
  line-height: 1.5;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-color: #d9d9d9;
  z-index: 1;
  border: 1px solid #EAEAEA;
}

.year-picker:hover {
  color: #40a9ff;
  background-color: #fff;
  /* border-color: #40a9ff; */
}

.year-picker__disabled .input-wrapper, .year-picker__disabled input{
  background-color: #DCDCDC;
  cursor: not-allowed;
}
.year-picker__disabled:active, .year-picker__disabled input{
  pointer-events: none;
}
.year-picker__disabled .css-imkype-Icon:hover{
  cursor: not-allowed;
}

/* HEADER */

.header {
  height: 40px;
  line-height: 40px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0 8px 0;
  background-color: #f8f8f8;
}

.current-year {
  display: inline-block;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
}

.nav-button {
  position: absolute;
  background-color: transparent;
  border: none;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  padding-bottom: 0;
  padding-top: 0;
}

.nav-button * {
  line-height: 40px;
}

.nav-button-next {
  right: 35px;
}

.nav-button-back {
  left: 35px;
}

.jump-button-next {
  right: 7px;
}

.jump-button-back {
  left: 7px;
}

.nav-button:hover {
  color: #1890ff;
}

/* BODY */
.body {
  border-top: 1px solid #e8e8e8;
  background: white;
  padding-top: 12px;
  padding-bottom: 20px;
}

.year-wrapper {
  /* color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  font-weight: bold;
  width: 33.3%;
  text-align: center; */
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  margin: 2px;
}

.year {
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
}

.selected-year, .year-wrapper:hover {
  background-color: #E7F1FD;
  color: #007BC3 !important;
  border-radius: 50% !important;
  font-weight: normal;
}


/* FOOTER */
a {
  cursor: pointer;
}

.footer {
  border-top: 1px solid #e8e8e8;
  line-height: 38px;
  padding: 0 12px;
}

.footer-btn {
  text-align: center;
  display: block;
}

.footer-today {
  display: inline-block;
  text-align: center;
  margin: 0;
  color: #1890ff;
  transition: color 0.3s ease;
}

.calendar__arrow{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendar__arrow:before {
  display: inline-block;
  border: solid;
  border-width: 0 1px 1px 0;
  content: "";
  border-color: inherit;
  padding: 3px;
}
.calendar__arrow--left{
  padding-left: 8px;
  padding-top: 10px;
}
.calendar__arrow--right{
  padding-right: 8px;
  padding-top: 10px;
}

.calendar__arrow--left:before {
  transform: rotate(135deg);
}

.calendar__arrow--right:before{
  transform: rotate(-45deg);
}
.label-current {
  width: 100%;
  font-weight: bold;
  text-align: center;
  background-color: #f2f8fc;
  line-height: 38px;
  cursor: pointer;
}
.label-current:hover{
  color: #8c8c8c;
}
.year-row{
  display: flex;
  justify-content: space-evenly;
}
.indicators-container div{
  padding: 0;
}
.picker-panel div::-webkit-scrollbar{
  width: 8px;
  background-color: #F5F5F5;         /* width of the entire scrollbar */
}
.picker-panel div ::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #F5F5F5;
}

.picker-panel div ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #bfbfbf;
}
.calendar__year-value{
  margin-left: 20px;
}
.out-side-year{
  opacity: 0.5;
  pointer-events: none;
}
.out-side-year:hover a{
  color: #666;
}